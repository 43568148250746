import styled from "styled-components";

import Azosans from "../../../assets/TEXTO-AzoSans-Regular.ttf";

export const Content = styled.div`
  padding-top: 4%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: black;
`;

export const MainContent = styled.div`
  padding: 3%;
  background-color: #eeeeee;
`;
export const SubContent = styled.div`
@media (min-width: 500px) {
  display: flex;
}
  padding: 3%;
  background-color: #eeeeee;
`;
export const OtherContent = styled.div`
padding: 20px;
@media (min-width: 500px) {
  display: flex;
}
@media (max-width: 500px) {
  width: 60%;
}
  height: 45%;
  width: 90%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #b3b3b3;
`;

export const TitleText = styled.text`
  
  color: #666666;
  font-size: 30pt;
  @media (min-width: 500px) {
    font-size: 30pt;
  }
  @media (max-width: 500px) {
    font-size: 9vmin;
    text-align: center;
  }
  font-weight: bold;
  font-family:"azo-sans-web";
    src:url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  src: url(${Azosans}) format("truetype");
`;

export const SmallTitleText = styled.text`
  color: #666666;
  @media (min-width: 500px) {
    font-size: 4vmin;
  }
  @media (max-width: 500px) {
    font-size: 14pt;
    text-align: center;
  }
  font-weight: bold;
  font-family:"azo-sans-web";
    src:url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  src: url(${Azosans}) format("truetype");
`;

export const BlueTitleText = styled.text`
  color: #46afef;
  font-size: 30pt;
  @media (min-width: 500px) {
    font-size: 30pt;
  }
  @media (max-width: 500px) {
    font-size: 9vmin;
    text-align: center;
  }

  font-weight: bold;
  font-family:"azo-sans-web";
    src:url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  src: url(${Azosans}) format("truetype");
`;
// width: 40%;
// float: right;
// display: block;
export const SmallText = styled.text`
  color: #666666;
  @media (min-width: 500px) {
    font-size: 2.3vmin;
  }
  @media (max-width: 500px) {
    font-size: 11pt;
    text-align: center;
  }
  font-family:"azo-sans-web";
    src:url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  src: url(${Azosans}) format("truetype");
`;

export const LeftContent = styled.div`
  @media (min-width: 500px) {
    width: 32%;
  }
  @media (max-width: 500px) {
    width: 92%;
  }
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  img {
    height: 100px;
  }
`;

export const RigthContent = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    justify-content: center;
  }
  @media (min-width: 500px) {
    width: 55%;
    margin-left: 15%;
  }
  /* background-color: #000000; */
`;

export const OtherLeftContent = styled.div`

justify-content: center;
text-align: center;
@media (min-width: 500px) {
  border-right: 1px solid rgba(70, 175, 239, .3);
  width: 32%;
}
@media (max-width: 500px) {
  border-bottom: 1px solid rgba(70, 175, 239, .3);
  width: 80%;
  padding-bottom: 10px;
}
display: flex;
flex-direction: column;
img {
  height: 100px;
}
`;

export const OtherCenterContent = styled.div`
text-align: center;
margin-left: 3%;
justify-content: center;
@media (min-width: 500px) {
  border-right: 1px solid rgba(70, 175, 239, .3);
  width: 32%;
}
@media (max-width: 500px) {
  border-bottom: 1px solid rgba(70, 175, 239, .3);
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
}
display: flex;
flex-direction: column;
img {
  height: 100px;
}
`;

export const OtherRightContent = styled.div`
text-align: center;
margin-left: 3%;
justify-content: center;
@media (min-width: 500px) {
  width: 32%;
}
@media (max-width: 500px) {
  width: 80%;
  padding-top: 10px;
}
display: flex;
flex-direction: column;
img {
  height: 100px;
}
`;