import { React } from "react";
import { RiCloseLine, RiArrowLeftLine } from "react-icons/ri";
import boarding from "../../../assets/Aguardando-Verde.png";
import inTheUnit from "../../../assets/Dinheiro-branco.png";
import inTheUnitOn from "../../../assets/Dinheiro-verde.png";
import delivered from "../../../assets/Em-rota-branco.png";
import deliveredOn from "../../../assets/Em-rota-verde.png";
import carrying from "../../../assets/Em-transporte-branco.png";
import carryingOn from "../../../assets/Em-transporte-verde.png";
import finish from "../../../assets/finish.png";
import finishOn from "../../../assets/finishOn.png";
import { addMoneyMask } from "../../convert-values/manipulateMoneyMask";
import "../../objectTrack/object-track-style.css";
import "../../productTrackingDescription/product-tracking-description-style.css";
import "./View-load-web-style.css";

const ViewLoadWebModal = ({
  onCloseViewLoadModal,
  closeViewLoadModal,
  receiveDetailCtes,
}) => {
  return (
    <>
      <div
        className="darkBG"
        onClick={() => {
          closeViewLoadModal(false);
          document.body.style.overflowY = "scroll";
        }}
      />
      <div className="centered">
        <div className="modal">
          <button
            className="returnBtnWeb"
            onClick={() => {
              onCloseViewLoadModal(false);
              document.body.style.overflowY = "hidden";
            }}
          >
            <RiArrowLeftLine style={{ marginBottom: "-3px" }} />
          </button>

          <div className="modalHeaderLoad">
            <div className="iconsStep">
              <div className="iconContainer">
                <img
                  src={
                    receiveDetailCtes.status.codigo >= 1 ? boarding : boarding
                  }
                  alt="carrying"
                  className="steps"
                />
                <div className="textIcon">
                  <h4
                    className={
                      receiveDetailCtes.status.codigo >= 1
                        ? "lineSelected"
                        : "line"
                    }
                  >
                    Aguardando Embarque
                  </h4>
                  {receiveDetailCtes.status.codigo >= 1 ? (
                    <h4 className="dateTextWeb">
                      {receiveDetailCtes.status.data_etapa_01}
                    </h4>
                  ) : null}
                </div>
              </div>

              <div className="iconContainer">
                <img
                  src={
                    receiveDetailCtes.status.codigo >= 2 ? carryingOn : carrying
                  }
                  alt="carrying"
                  className="steps"
                />
                <div className="textIcon">
                  <span
                    className={
                      receiveDetailCtes.status.codigo >= 2
                        ? "lineSelected"
                        : "line"
                    }
                  >
                    Em transporte
                  </span>
                  {receiveDetailCtes.status.codigo >= 2 ? (
                    <h4 className="dateTextWeb">
                      {receiveDetailCtes.status.data_etapa_02}
                    </h4>
                  ) : null}
                </div>
                {/*
                <div className="textIcon">
                     <span className="line">Em transporte</span>
</div> */}
              </div>

              <div className="iconContainer">
                <img
                  src={
                    receiveDetailCtes.status.codigo >= 3 ? carryingOn : carrying
                  }
                  alt="carrying"
                  className="steps"
                />
                <div className="textIcon">
                  <span
                    className={
                      receiveDetailCtes.status.codigo >= 3
                        ? "lineSelected"
                        : "line"
                    }
                  >
                    Na unidade final
                  </span>
                  {receiveDetailCtes.status.codigo >= 3 ? (
                    <h4 className="dateTextWeb">
                      {receiveDetailCtes.status.data_etapa_03}
                    </h4>
                  ) : null}
                </div>

                {/*
                <div className="textIcon">
                     <span className="line">Na unidade final</span>
</div> */}
              </div>
              {/*
              <div className="iconContainer">
                <img
                  src={
                    receiveDetailCtes.status.codigo >= 4
                      ? inTheUnitOn
                      : inTheUnit
                  }
                  alt="OnTheRoute"
                  className="steps"
                />
                <div className="textIcon">
                  <span
                    className={
                      receiveDetailCtes.status.codigo >= 4
                        ? "lineSelected"
                        : "line"
                    }
                  >
                    Aguardando pagamento do DAE
                  </span>
                  {receiveDetailCtes.status.codigo >= 4 ? (
                    <h4 className="dateTextWeb">
                      {receiveDetailCtes.status.data_etapa_04}
                    </h4>
                  ) : null}
                </div>
              </div>
              */}
              <div className="iconContainer">
                <img
                  src={
                    receiveDetailCtes.status.codigo >= 5
                      ? deliveredOn
                      : delivered
                  }
                  alt="Delivered"
                  className="steps"
                />
                <div className="textIcon">
                  <span
                    className={
                      receiveDetailCtes.status.codigo >= 5
                        ? "lineSelected"
                        : "line"
                    }
                  >
                    Em rota de entrega
                  </span>
                  {receiveDetailCtes.status.codigo >= 5 ? (
                    <h4 className="dateTextWeb">
                      {receiveDetailCtes.status.data_etapa_05}
                    </h4>
                  ) : null}
                </div>
              </div>
              <div className="iconContainer">
                <img
                  src={receiveDetailCtes.status.codigo >= 6 ? finishOn : finish}
                  alt="Finish"
                  className="steps"
                />
                <div className="textIcon">
                  <span
                    className={
                      receiveDetailCtes.status.codigo >= 6
                        ? "lineSelected"
                        : "line"
                    }
                  >
                    Entregue
                  </span>
                  {receiveDetailCtes.status.codigo >= 6 ? (
                    <h4 className="dateTextWeb">
                      {receiveDetailCtes.status.data_etapa_06}
                    </h4>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modalContentBottom">
            <div className="containerDescriptionWeb">
              <h2 className="titleTextWeb">Descrição do produto</h2>
              <div className="containerEmissionDateWeb">
                <h3 className="emissionDateWeb">
                  Previsão de chegada na unidade {receiveDetailCtes.data_previsao_entrega}
                </h3>
              </div>
              <div className="containerAllTextWeb">
                <div className="containerTextInfoWeb">
                  <span className="textInfoWeb">Número</span>
                  <span className="textInfoWeb">Nota fiscal</span>
                  <span className="textInfoWeb">Emissão</span>
                  <span className="textInfoWeb">Remetente</span>
                  <span className="textInfoWeb">Destinatário</span>
                  <span className="textInfoWeb">Valor da nota</span>
                  <span className="textInfoWeb">Peso</span>
                  <span className="textInfoWeb">Volume</span>
                </div>
                <div className="detailsWeb">
                  <span className="textInfoWeb">{receiveDetailCtes.cte}</span>
                  <span className="textInfoWeb">
                    {receiveDetailCtes.nota_fiscal.numero}
                  </span>
                  <span className="textInfoWeb">
                    {receiveDetailCtes.data_emissao}
                  </span>
                  <span className="textInfoWeb">
                    {receiveDetailCtes.remetente}
                  </span>
                  <span className="textInfoWeb">
                    {receiveDetailCtes.destinatario}
                  </span>
                  <span className="textInfoWeb">
                    {addMoneyMask(receiveDetailCtes.nota_fiscal.valor)}
                  </span>
                  <span className="textInfoWeb">
                    {receiveDetailCtes.peso_cobrado} KG
                  </span>
                  <span className="textInfoWeb">
                    {receiveDetailCtes.nota_fiscal.qtde_volumes}
                  </span>
                </div>
                {/*
                <div className="containerButtonWeb">
                  <button className="buttonGeneratorWeb">
                    Gerar boleto DAE
                  </button>
                </div>
              */}
              </div>
            </div>
          </div>
          <div className="modalActions"></div>
        </div>
      </div>
    </>
  );
};

export default ViewLoadWebModal;
