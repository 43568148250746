import { Container } from "./styles";
import { IoClose } from "react-icons/io5";
import { useEffect } from "react";
import { HashLink } from 'react-router-hash-link';
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import {
  BrowserRouter as Router, 
} from 'react-router-dom';

export function MenuMobile({
  menuIsVisible,
  setMenuIsVisible,
  onOpenModalLogin,
}) {

  useEffect(() => {
    console.log('device => ', isDesktop)
  }, []);
  return (
    <Container isVisible={menuIsVisible}>
      <IoClose
        size={35}
        color="#00aeef"
        onClick={() => setMenuIsVisible(false)}
      />
      <nav>
      <Router> 
        <span><HashLink style={{ color: '#00aeef', textDecoration: 'none' }} smooth to="#aboutsectionzero">Inicio</HashLink></span>
        <span><HashLink style={{ color: '#00aeef', textDecoration: 'none' }} smooth to="#aboutsectionone">Quem somos</HashLink></span>
        <span><HashLink style={{ color: '#00aeef', textDecoration: 'none' }} smooth to="#aboutsectiontwo">Serviços</HashLink></span>
        <span><HashLink style={{ color: '#00aeef', textDecoration: 'none' }} smooth to="#aboutsectionthree">Onde Atuamos</HashLink></span>
        <span><HashLink style={{ color: '#00aeef', textDecoration: 'none' }} smooth to="#aboutsectionfour">Contato</HashLink></span>
      </Router>
        <span
          onClick={() => {
            document.body.style.overflowY = "hidden";
            onOpenModalLogin(true);
          }}
        >
          Localize sua carga
        </span>
      </nav>
    </Container>
  );
}
