import React, { useState } from "react";
import FirstImage from "../../assets/grupoDeMascara296.png";
import SecondImage from "../../assets/grupoDeMascara297.png";
import "./styles/aboutsectionone.css";
import { BrowserView, MobileView } from "react-device-detect";
import OurFleetPopUp from "../pop-ups/our-fleet-pop-up/Our-fleet-pop-up";
import OurInstallationsPopUp from "../pop-ups/our-installations-pop-up/Our-installations-pop-up";
import {
  BlueTitleText,
  CardDiv,
  Content,
  LeftContent,
  RigthContent,
  SmallCardText,
  SmallText,
  TitleText,
} from "./styles/aboutsectionone.styles";

function AboutSectionOne() {
  const [ourFleetPopUpIsOpen, setOurFleetPopUpIsOpen] = useState(false);
  const [ourInstallationsPopUpIsOpen, setOurInstallationsPopUpIsOpen] =
    useState(false);
  const [opac, setOpac] = useState("0.7");
  const [opacSec, setOpacSec] = useState("0.7");
  const imageStyle = {
    opacity: `${opac}`,
    width: 320,
    height: 220,
    borderStartEndRadius: "15px",
    borderStartStartRadius: "15px",
  };
  const imageStyleMobile = {
    opacity: `${opac}`,
    width: 250,
    height: 150,
    borderStartEndRadius: "15px",
    borderStartStartRadius: "15px",
  };
  const secondImageStyle = {
    opacity: `${opacSec}`,
    width: 320,
    height: 220,
    borderStartEndRadius: "15px",
    borderStartStartRadius: "15px",
  };
  const secondImageStyleMobile = {
    opacity: `${opacSec}`,
    width: 250,
    height: 150,
    borderStartEndRadius: "15px",
    borderStartStartRadius: "15px",
  };
  const handleHoverFirstImage = (decimal) => {
    setOpac(decimal);
  };
  const handleHoverSecondImage = (decimal) => {
    setOpacSec(decimal);
  };
  return (
    <Content translate="no" id="aboutsectionone">
      {ourFleetPopUpIsOpen && (
        <OurFleetPopUp
          setOutFleetPopUpIsOpen={(isVisible) => {
            setOurFleetPopUpIsOpen(false);
          }}
        />
      )}
      {ourInstallationsPopUpIsOpen && (
        <OurInstallationsPopUp
          setOurInstallationsPopUpIsOpen={(isVisible) => {
            setOurInstallationsPopUpIsOpen(false);
          }}
        />
      )}
      <LeftContent>
        <TitleText>
          Conheça um pouco
          <br /> da nossa <BlueTitleText>história</BlueTitleText>.
        </TitleText>
        <SmallText>
          A Transportadora Econômica Ltda. é uma empresa de vasta experiência,
          que atua no setor de Serviços de Transporte de Cargas Secas Gerais e
          Encomendas. Nós atendemos um universo de mais de 70.000 clientes, que
          estão distribuídos em nossas 5 Bases espalhadas por 4 estados, fazemos
          através destas (bases), especialmente a integração entre a Região
          Sudeste e Nordeste, com atuação em mais de 500 cidades.
        </SmallText>
      </LeftContent>
      <RigthContent>
        <CardDiv>
          <BrowserView>
            <img
              onClick={() => setOurFleetPopUpIsOpen(true)}
              style={imageStyle}
              src={FirstImage}
              alt="logo"
              onMouseEnter={() => handleHoverFirstImage(0.2)}
              onMouseLeave={() => handleHoverFirstImage(0.7)}
            />
          </BrowserView>
          <MobileView>
            <img
              onClick={() => setOurFleetPopUpIsOpen(true)}
              style={imageStyleMobile}
              src={FirstImage}
              alt="logo"
              onMouseEnter={() => handleHoverFirstImage(0.2)}
              onMouseLeave={() => handleHoverFirstImage(0.7)}
            />
          </MobileView>
          <SmallCardText>Nossa Frota</SmallCardText>
        </CardDiv>
        <CardDiv>
          <BrowserView>
            <img
              onClick={() => setOurInstallationsPopUpIsOpen(true)}
              style={secondImageStyle}
              src={SecondImage}
              alt="logo"
              onMouseEnter={() => handleHoverSecondImage(0.2)}
              onMouseLeave={() => handleHoverSecondImage(0.7)}
            />
          </BrowserView>
          <MobileView>
            <img
              onClick={() => setOurInstallationsPopUpIsOpen(true)}
              style={secondImageStyleMobile}
              src={SecondImage}
              alt="logo"
              onMouseEnter={() => handleHoverFirstImage(0.2)}
              onMouseLeave={() => handleHoverFirstImage(0.7)}
            />
          </MobileView>
          <SmallCardText>Nossas Instalações</SmallCardText>
        </CardDiv>
      </RigthContent>
    </Content>
  );
}

export default AboutSectionOne;
