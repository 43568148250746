import { React } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import firstImage from "../../../assets/grupoDeMascara294.png";
import secondImage from "../../../assets/grupoDeMascara295.png";
import "./our-installations-pop-up.css";
const OurInstallationsPopUp = ({ setOurInstallationsPopUpIsOpen }) => {
  return (
    <>
      <div
        className="popUpWhiteBG"
        onClick={() => setOurInstallationsPopUpIsOpen(false)}
      />

      <div className="centeredPopUp">
        <AiOutlineCloseCircle
          onClick={() => setOurInstallationsPopUpIsOpen(false)}
          style={{
            display: "flex",
            alignSelf: "flex-end",
            position: "absolute",
            color: "black",
            fontSize: "4vmax",
            cursor: "pointer",
          }}
        />
        <div className="txt-main">
          <h1 className="txt-title">Nossas instalações.</h1>
          <span className="txt-default">
            Estamos prontos para atuarmos na administração de estoques através
            de EDI* e CDs* de Distribuição e Armazenagem em Geral. Nossa equipe
            de profissionais está sempre preparada para atender sua empresa com
            toda atenção necessária. Isto tudo integrado com uma tecnologia
            impar, interligando on-line nossas filiais e possibilitando uma
            integração entre a Econômica. Daí o porquê convidamos sua empresa a
            ser usuária de nossos serviços e gozar de privilégios especiais.
          </span>
        </div>
        <img
          style={{ marginTop: "5%", height: "100%", width: "100%" }}
          src={firstImage}
          alt="firstImage"
        />
        <br />
        <img
          style={{ marginTop: "5%", height: "100%", width: "100%" }}
          src={secondImage}
          alt="secondImage"
        />
      </div>
    </>
  );
};

export default OurInstallationsPopUp;
