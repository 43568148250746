import { React } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import firstImage from "../../../assets/grupoDeMascara292.png";
import secondImage from "../../../assets/grupoDeMascara293.png";
import "./our-fleet-pop-up.css";
const OurFleetPopUp = ({ setOutFleetPopUpIsOpen }) => {
  return (
    <>
      <div
        className="popUpWhiteBG"
        onClick={() => setOutFleetPopUpIsOpen(false)}
      />

      <div className="centeredPopUp">
        <AiOutlineCloseCircle
          onClick={() => setOutFleetPopUpIsOpen(false)}
          style={{
            display: "flex",
            alignSelf: "flex-end",
            position: "absolute",
            color: "black",
            fontSize: "4vmax",
            cursor: "pointer",
          }}
        />
        <div className="txt-main">
          <h1 className="txt-title">Conheça nossa frota.</h1>
          <span className="txt-default">
            O objetivo da nossa empresa é oferecer aos nossos clientes um
            serviço de qualidade, com preços competitivos, sem comprometer nossa
            solidez, agilidade e modernização. Para isto, contamos com frota
            própria e veículos agregados com furgões, tipo baú, e todos
            rastreados via satélite, proporcionando completa segurança à sua
            carga; nossos depósitos estão prontos para atuar nas condições mais
            versáteis, para atender nossos clientes, desde a coleta, a
            armazenagem, o embarque e a distribuição.
          </span>
        </div>
        <img
          style={{ marginTop: "5%", height: "100%", width: "100%" }}
          src={firstImage}
          alt="firstImage"
        />
        <br />
        <img
          style={{ marginTop: "5%", height: "100%", width: "100%" }}
          src={secondImage}
          alt="secondImage"
        />
      </div>
    </>
  );
};

export default OurFleetPopUp;
