import { React, useRef, useState, useEffect } from "react";
import { RiCloseLine, RiDownload2Line, RiArrowLeftLine } from "react-icons/ri";
import ClipLoader from "react-spinners/ScaleLoader";

import logo from "../../../assets/te_logo.png";
import "./Modal.css";
const Modal = ({ onCloseModal, onOpenModalLoadDetail, onReturnLoginModal, sendDetailCtes }) => {
  const [loading, setLoading] = useState(false);

  function getCtes(query) {
    setCtes([]);
    setLoading(true);
    var axios = require("axios");
    var data = JSON.stringify({
      token: localStorage.getItem("token-service"),
      initialDate: ref.current.value ? ref.current.value : '',
      finalDate: ref2.current.value ? ref2.current.value : '',
      query: query ? { query } : undefined,
    });

    var config = {
      method: "post",
      url: "https://transportadora-backend.deway.com.br/ctes",
      // url: "http://localhost:5000/ctes", 
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        console.log(response.data);
        setCtes(response.data.ctes);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  }

  const [ctes, setCtes] = useState([]);
  const [detailCtes, setDetailCtes] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getCtes(undefined);
  }, []);

  const [filterStr, setFilterStr] = useState("");

  const ref = useRef();
  const ref2 = useRef();

  return (
    <>
      <div
        className="darkBG"
        onClick={() => {
          onCloseModal(false);

          document.body.style.overflowY = "scroll";
        }}
      />
      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
            <div className="modalHeaderLeft">
              <img src={logo} alt="logo" />
              <span>
                Informe o Número do Conhecimento ou da Nota Fiscal da carga que
                deseja localizar.
              </span>
            </div>
            <div className="modalHeaderRight">
              <button
                className="returnBtn"
                onClick={() => {
                  onReturnLoginModal(false);
                  document.body.style.overflowY = "hidden";
                }}
              >
                <RiArrowLeftLine style={{ marginBottom: "-3px" }} />
              </button>
              {/* <div className="modalHeaderRightSpans">
                <span>Justo Dantas</span>
                <span>CNPJ 00000000000000</span>
              </div> */}
            </div>
          </div>
          <div className="modalHeaderBottom">
            <input
              type="text"
              value={search}
              onChange={(e) => {
                // setFilterStr(e.target.value) || setFilterStr(e.target.value);
                setSearch(e.target.value);
              }}
              className="searchInput"
              placeholder="Busque pelo código de CTe ou nota fiscal"
            />
            <button
              className="searchButton"
              type="button"
              onClick={() => {
                getCtes(search);
              }}
            >
              Localizar
            </button>
            <input
              className="dateInput"
              placeholder="Periodo inicial"
              type="text"
              ref={ref}
              onFocus={() => (ref.current.type = "date")}
              onBlur={() => (ref.current.type = "date")}
            />
            <input
              className="dateInput"
              placeholder="Periodo final"
              type="text"
              ref={ref2}
              onFocus={() => (ref2.current.type = "date")}
              onBlur={() => (ref2.current.type = "date")}
            />
          </div>
          <button
            className="closeBtn"
            onClick={() => {
              onCloseModal(false);
              document.body.style.overflowY = "scroll";
            }}
          >
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: "50px",
                marginTop: "20px",
              }}
            >
              <ClipLoader color="#FFF" height="14" width="5" />
            </div>
          ) : null}
          <div className="modalContent">
            {ctes.length > 0 ? (
              ctes.map((service, i) => {
                return (
                  <>
                    <div style={{ display: "flex" }}>
                      <div className="content">
                        <div>
                          <text className="contentTitle">Número do CTe</text>
                          <br />
                          <span className="contentSpan">{service.cte}</span>
                        </div>
                        <div className="contentSeparator">
                          <text className="contentTitle">Nota físcal</text>
                          <br />
                          <span>{service.nota_fiscal.numero}</span>
                        </div>
                        <div className="contentSeparator">
                          <text className="contentTitle">Remetente</text>
                          <br />
                          <span>{service.remetente}</span>
                        </div>
                        <div className="contentSeparator">
                          <text className="contentTitle">Destino</text>
                          <br />
                          <span>{service.destinatario}</span>
                        </div>
                        <div className="contentSeparator">
                          <text className="contentTitle">Trecho</text>
                          <br />
                          <span>{service.trecho}</span>
                        </div>
                        <div className="contentSeparator">
                          <text className="contentTitle">Status</text>
                          <br />
                          <span>{service.status.descricao}</span>
                        </div>
                        <div className="contentSeparatorFinal">
                        <text
                          translate="no"
                          onClick={() => onOpenModalLoadDetail(false, service)}
                        >
                          Visualizar
                        </text>
                      </div>
                      </div>
                      {/* <div className="downloadContent">
                      <RiDownload2Line style={{ fontSize: "1.4vw" }} />
                      <span style={{ alignSelf: "center" }}>
                        Comprovante de entrega
                      </span>
                    </div> */}
                    </div>
                  </>
                );
              })
            ) : (
              <center>
                {!loading ? (
                  <div
                    style={{
                      color: "#FFF",
                      fontSize: "20px ",
                    }}
                  >
                    Nenhum resultado encontrado
                  </div>
                ) : null}
              </center>
            )}
          </div>
          <div className="modalActions"></div>
        </div>
      </div>
    </>
  );
};

export default Modal;
