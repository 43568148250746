export function addMoneyMask(amount) {
  let decimalCount = 2;
  const decimal = ',';
  const thousands = '.';

  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-' : '';

  const amountInteger = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
  ).toString();
  const amountLength = amountInteger.length > 3 ? amountInteger.length % 3 : 0;

  return `R$ ${negativeSign}${
    amountLength ? amountInteger.substr(0, amountLength) + thousands : ''
  }${amountInteger
    .substr(amountLength)
    .replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${
    decimalCount
      ? decimal
        + Math.abs(amount - amountInteger)
          .toFixed(decimalCount)
          .slice(2)
      : ''
  }`;
}

export function removeMoneyMask(value) {
  return value
    .replace('.', '')
    .replace(',', '.')
    .replace('R$', '')
    .replace(' ', '');
}
