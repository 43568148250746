import styled from "styled-components";


export const Content = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media (min-width: 500px) {
    display: flex;
  }
  color: black;
`;

export const LeftContent = styled.div`
  @media (min-width: 500px) {
    width: 32%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  margin-left: 6%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  img {
    height: 100px;
  }
`;

export const TitleText = styled.text`
  color: #666666;
  font-size: 30pt;
  @media (min-width: 500px) {
    font-size: 30pt;
    padding: 5px 25px 5px 25px;
  }
  @media (max-width: 500px) {
    font-size: 24pt;
    text-align: center;
  }
  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const BlueTitleText = styled.text`
  color: #46afef;
  font-size: 30pt;
  @media (max-width: 500px) {
    font-size: 24pt;
    text-align: center;
  }

  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const SmallText = styled.text`
  color: #666666;
  @media (min-width: 500px) {
    font-size: 2.5vmin;
    margin-left: 25px;
  }
  @media (max-width: 500px) {
    font-size: 11pt;
    text-align: center;
  }
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;
export const CardDiv = styled.text`
  margin-left: 2%;
  text-align: center;
  border-radius: 15px;
  margin-top: 70px;
  background-color: #084ca1;
  width: 320px;
  height: 260px;
  @media (max-width: 1023px) {
    width: 250px;
    height: 190px;
  }
`;

export const SmallCardText = styled.text`
  color: white;
  font-size: 14pt;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const RigthContent = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    justify-content: center;
  }
  @media (min-width: 500px) {
    width: 60%;
    margin-left: 5%;
  }
  /* background-color: #000000; */
`;
