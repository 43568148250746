import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media (min-width: 500px) {
    display: flex;
  }
  color: black;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 50pt;
`;

export const TitleText = styled.text`
  color: #666666;
  font-size: 30pt;
  @media (min-width: 500px) {
    font-size: 30pt;
    padding: 5px 25px 5px 25px;
  }
  @media (max-width: 500px) {
    font-size: 24pt;
    text-align: center;
  }
  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const BlueTitleText = styled.text`
  color: #46afef;
  font-size: 30pt;
  @media (max-width: 500px) {
    font-size: 24pt;
    text-align: center;
  }

  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const SmallText = styled.text`
  padding-top: 1.2vmin;
  color: #666666;
  width: 65%;
  text-align: center;
  @media (min-width: 500px) {
    font-size: 2.5vmin;
    margin-left: 25px;
  }
  @media (max-width: 500px) {
    font-size: 11pt;
    text-align: center;
  }
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const SponsorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IgmSponsor = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  padding-top: 8vmin;
  img {
    height: ${(props) => props.height};
    width: auto;
    padding-top: 20px;
  }
  @media (max-width: 1200px) {
    img {
      height: auto;
      width: 110pt;
    }
  }
  @media (max-width: 500px) {
    width: 90%;
    img {
      margin-bottom: 8pt;
      height: auto;
      width: 100%;
    }
  }
`;

export const SecondaryText = styled.text`
  color: #666666;
  font-size: 20pt;
  @media (min-width: 500px) {
    font-size: 20pt;
    padding: 5px 25px 5px 25px;
  }
  @media (max-width: 500px) {
    font-size: 15pt;
    text-align: center;
  }
  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const BlueSecondaryText = styled.text`
  color: #46afef;
  font-size: 20pt;

  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;
