import { React } from "react";
import { RiCloseLine, RiArrowLeftLine } from "react-icons/ri";
import delivered from "../../../assets/deliveredOnMobile.png";
import carrying from "../../../assets/carryingOnMobile.png";
import finish from "../../../assets/finishOn.png";
import payment from "../../../assets/PayDaeMobile.png";
import boarding from "../../../assets/step1.png";
import { addMoneyMask } from "../../convert-values/manipulateMoneyMask";
import "../../objectTrack-mobile/object-track-mobile-style.css";
import "../../productTrackingDescription-mobile/product-tracking-mobile-description-style.css";
import "./View-load-mobile-style.css";
const ViewLoadMobileModal = ({
  setModalMobileIsOpen,
  receiveMobileDetailCtes,
  onCloseViewLoadMobileModal,
}) => {
  return (
    <>
      <div
        className="darkBG"
        onClick={() => {
          setModalMobileIsOpen(false);
          document.body.style.overflowY = "scroll";
        }}
      />
      <div className="centered">
        <div className="modal">
        <button
            className="returnBtnWeb"
            onClick={() => {
              onCloseViewLoadMobileModal(false);
              document.body.style.overflowY = "hidden";
            }}
          >
            <RiArrowLeftLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalHeaderLoadMobile">
            <div className="iconsStep">
              <div className="iconContainer">
                <img
                  src={
                    receiveMobileDetailCtes.status.codigo === 1
                      ? boarding
                      : receiveMobileDetailCtes.status.codigo === 2
                      ? carrying
                      : receiveMobileDetailCtes.status.codigo === 3
                      ? payment
                      : receiveMobileDetailCtes.status.codigo === 4
                      ? payment
                      : receiveMobileDetailCtes.status.codigo === 5
                      ? delivered
                      : receiveMobileDetailCtes.status.codigo === 6
                      ? finish
                      : boarding
                  }
                  alt="boarding"
                  className="steps"
                />

                <div className="textIcon">
                  <h4 className="lineSelected">
                    {receiveMobileDetailCtes.status.codigo === 1
                      ? "Aguardando embarque"
                      : receiveMobileDetailCtes.status.codigo === 2
                      ? "Em transporte"
                      : receiveMobileDetailCtes.status.codigo === 3
                      ? "Na unidade final"
                      : receiveMobileDetailCtes.status.codigo === 4
                      ? "Aguardando pagamento do DAE"
                      : receiveMobileDetailCtes.status.codigo === 5
                      ? "Em rota de entrega"
                      : receiveMobileDetailCtes.status.codigo === 6
                      ? "Entregue"
                      : "Aguardando embarque"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="modalHeaderBottomInfoMobile">
            <div className="trackingDateMobile">
              <h4 className="dateText">
                {receiveMobileDetailCtes.status.codigo === 1
                  ? receiveMobileDetailCtes.status.data_etapa_01
                  : receiveMobileDetailCtes.status.codigo === 2
                  ? receiveMobileDetailCtes.status.data_etapa_02
                  : receiveMobileDetailCtes.status.codigo === 3
                  ? receiveMobileDetailCtes.status.data_etapa_03
                  : receiveMobileDetailCtes.status.codigo === 4
                  ? receiveMobileDetailCtes.status.data_etapa_04
                  : receiveMobileDetailCtes.status.codigo === 5
                  ? receiveMobileDetailCtes.status.data_etapa_05
                  : receiveMobileDetailCtes.status.codigo === 6
                  ? receiveMobileDetailCtes.status.data_etapa_06
                  : receiveMobileDetailCtes.status.data_etapa_01}
              </h4>
            </div>
          </div>

          <div className="modalContentBottomMobile">
            <div className="containerDescription">
              <div className="containerEmissionDate">
                <h3 className="emissionDate">
                  Previsão de chegada na unidade{" "}
                  {receiveMobileDetailCtes.data_previsao_entrega}
                </h3>
                <h2 className="titleText">Descrição do produto</h2>
              </div>
              <div className="containerAllText">
                <div className="containerTextInfo">
                  <span className="textInfo">Número</span>
                  <span className="textInfo">Nota fiscal</span>
                  <span className="textInfo">Emissão</span>
                  <span className="textInfo">Remetente</span>
                  <span className="textInfo">Destinatário</span>
                  <span className="textInfo">Valor da nota</span>
                  <span className="textInfo">Peso</span>
                  <span className="textInfo">Volume</span>
                </div>
                <div className="details">
                  <span className="textInfo">
                    {receiveMobileDetailCtes.cte}
                  </span>
                  <span className="textInfo">
                    {receiveMobileDetailCtes.nota_fiscal.numero}
                  </span>
                  <span className="textInfo">
                    {receiveMobileDetailCtes.data_emissao}
                  </span>
                  <span className="textInfo">
                    {receiveMobileDetailCtes.remetente}
                  </span>
                  <span className="textInfo">
                    {receiveMobileDetailCtes.destinatario}
                  </span>
                  <span className="textInfo">
                    {addMoneyMask(receiveMobileDetailCtes.nota_fiscal.valor)}
                  </span>
                  <span className="textInfo">
                    {receiveMobileDetailCtes.peso_cobrado} KG
                  </span>
                  <span className="textInfo">
                    {receiveMobileDetailCtes.nota_fiscal.qtde_volumes}
                  </span>
                </div>
              </div>
              {/*
              <div className="containerButton">
                <button className="buttonGenerator">Gerar boleto DAE</button>
              </div>
              */}
            </div>
          </div>
          <div className="modalActions"></div>
        </div>
      </div>
    </>
  );
};

export default ViewLoadMobileModal;
