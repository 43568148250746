import React from "react";
import About from "../components/about/index";
import FooterSection from "../components/footer/";
import Header from "../components/header";

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <FooterSection />
    </div>
  );
}

export default App;
