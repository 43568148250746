import { React, useState, useEffect } from "react";
import { BrowserView, MobileView, CustomView } from "react-device-detect";
import { RiMenu3Fill } from "react-icons/ri";
import logo from "../../assets/te_logo.png";
import Button from "../button";
import { MenuMobile } from "../menuMobile";
import ModalMobile from "../modals/list-modal-mobile/ModalMobile";
import Modal from "../modals/list-modal/Modal";
import LoginModal from "../modals/login-modal/Login-modal";
import ViewLoadMobileModal from "../modals/view-load-mobile/View-load-mobile";
import ViewLoadWebModal from "../modals/view-load-web/View-load-web";
import "./Header.css";
import { Content, LeftContent, RigthContent } from "./Header.styles";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sendDetailCtes, setSendDetailCtes] = useState([]);
  const [sendMobileDetailCtes, setMobileSendDetailCtes] = useState([]);
  const [modalMobileIsOpen, setModalMobileIsOpen] = useState(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  return (
    <Content id="aboutsectionzero">
      <MenuMobile
        onOpenModalLogin={(isVisible) => {
          setMenuIsVisible(false);
          setLoginModalIsOpen(true);
        }}
        menuIsVisible={menuIsVisible}
        setMenuIsVisible={setMenuIsVisible}
      />
      <LeftContent>
        <img
          style={{
            height: "auto",
            width: "45%",
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-start",
          }}
          src={logo}
          alt="logo"
        />
        <h1 className="titleHeaderWeb">
          A segurança <br />
          em transporte.
        </h1>
        <span style={{ marginLeft: "5vh" }}>
          A transportadora econômica é a empresa <br />
          que vai te ajudar com o que precisar
        </span>
        <Router>
          <HashLink
            style={{ color: "white", textDecoration: "none" }}
            smooth
            to="#aboutsectionfour"
          >
            <Button
              width={"15vmin"}
              marginLeft={"4vh"}
              text={"Fale conosco"}
            ></Button>
          </HashLink>
        </Router>
        <BrowserView>
          {loginModalIsOpen && (
            <LoginModal
              setLoginModalIsOpen={setLoginModalIsOpen}
              onOpenListModal={(isVisible) => {
                setLoginModalIsOpen(false);
                setIsOpen(true);
              }}
            />
          )}
          {modalIsOpen && (
            <ViewLoadWebModal
              receiveDetailCtes={sendDetailCtes}
              closeViewLoadModal={(isVisible) => {
                setModalIsOpen(false);
              }}
              onCloseViewLoadModal={(isVisible) => {
                setIsOpen(true);
                setModalIsOpen(false);
              }}
            />
          )}
          {isOpen && (
            <Modal
              onCloseModal={(isVisible) => setIsOpen(isVisible)}
              onReturnLoginModal={(isVisible) => {
                setIsOpen(false);
                setLoginModalIsOpen(true);
              }}
              onOpenModalLoadDetail={(isVisible, ctes) => {
                setIsOpen(false);
                setSendDetailCtes(ctes);
                setModalIsOpen(true);
              }}
            />
          )}
        </BrowserView>
        <MobileView>
          {modalMobileIsOpen && (
            <ViewLoadMobileModal
              onCloseViewLoadMobileModal={(isVisible) => {
                setIsOpenMobile(true);
                setModalMobileIsOpen(false);
              }}
              receiveMobileDetailCtes={sendMobileDetailCtes}
              setModalMobileIsOpen={setModalMobileIsOpen}
            />
          )}
          {loginModalIsOpen && (
            <LoginModal
              setLoginModalIsOpen={setLoginModalIsOpen}
              onOpenListModal={(isVisible) => {
                setLoginModalIsOpen(false);
                setIsOpenMobile(true);
              }}
            />
          )}
          {isOpenMobile && (
            <ModalMobile
              onCloseModalMobile={(isVisible) => setIsOpenMobile(isVisible)}
              onReturnLoginMobileModal={(isVisible) => {
                setIsOpenMobile(false);
                setLoginModalIsOpen(true);
              }}
              onOpenModalMobileLoadDetail={(isVisible, ctes) => {
                setIsOpenMobile(false);
                setMobileSendDetailCtes(ctes);
                setModalMobileIsOpen(true);
              }}
            />
          )}
        </MobileView>
      </LeftContent>
      <BrowserView>
        <RigthContent>
          <div>
            <Router>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionzero"
                >
                  Inicio
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionone"
                >
                  Quem Somos
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectiontwo"
                >
                  Serviços
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionthree"
                >
                  Onde Atuamos
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionfour"
                >
                  Contato
                </HashLink>
              </span>
            </Router>
            <button
              className="btn-contact-us"
              onClick={() => {
                setLoginModalIsOpen(true);
                document.body.style.overflowY = "hidden";
              }}
            >
              Localize sua carga
            </button>
            {/* <MobileView>
            <button
              className="btn-contact-us"
              onClick={() => setIsOpen(true)}
            />
          </MobileView> */}
            {/* <Button width={"19vmin"} text={"Localize sua carga"} /> */}
          </div>
        </RigthContent>
      </BrowserView>
      <MobileView className="pos-main">
        <RigthContent>
          <RiMenu3Fill onClick={() => setMenuIsVisible(true)} size={45} />
        </RigthContent>
      </MobileView>
    </Content>
  );
}

export default Header;
