import React from "react";
import BeachPark from "../../assets/beach_park_logo.png";
import Doutex from "../../assets/doutex.png";
import Cotece from "../../assets/download.png";
import Betania from "../../assets/grupoDeMascara290.png";
import Dakota from "../../assets/logoDakota.png";
import Planq from "../../assets/logoplanquimica.png";
import Rosset from "../../assets/logoRosset.png";
import Lubrizol from "../../assets/lubrizol.png";
import Petrobras from "../../assets/petrobrashorizontallogointernationalsvg.png";
import Pemalex from "../../assets/x152574g.png";
import Sealy from "../../assets/x440pxSealyCorporationBrandsvg.png";
import "./styles/aboutsectionone.css";
import {
  BlueSecondaryText,
  BlueTitleText,
  Content,
  IgmSponsor,
  SecondaryText,
  SmallText,
  SponsorContent,
  TextContent,
  TitleText,
} from "./styles/aboutsectionthree.styles";

function AboutSectionThree() {
  return (
    <Content id="aboutsectionthree" translate="no">
      <TextContent>
        <TitleText>
          Onde atuamos no <BlueTitleText>mercado</BlueTitleText>?
        </TitleText>
        <SmallText>
          Nós atendemos a um universo de mais de 70.000 clientes, que estão
          distribuídos em nossas 5 bases espalhadas por 4 estados, são eles: São
          Paulo, Rio de Janeiro, Mossoró no Rio Grande do Norte e em Fortaleza no
          Ceará. Fazemos através destas (bases), especialmente a integração
          entre a Região sudeste e Nordeste, com atuação em mais de 500 cidades.
        </SmallText>
      </TextContent>
      <SponsorContent>
        <BlueSecondaryText>Algumas empresas</BlueSecondaryText>
        <IgmSponsor>
          <img src={Doutex} height={"45pt"} alt="logo" />
          <img src={Rosset} height={"80pt"} alt="logo" />
          <img src={Sealy} height={"170pt"} alt="logo" />
          <img src={Planq} height={"70pt"} alt="logo" />
          <img src={Lubrizol} height={"60pt"} alt="logo" />
          <img src={Petrobras} height={"100pt"} alt="logo" />
          <img src={Cotece} height={"80pt"} alt="logo" />
          <img src={Betania} height={"100pt"} alt="logo" />
          <img src={BeachPark} height={"230pt"} alt="logo" />
          <img src={Pemalex} height={"150pt"} alt="logo" />
          <img src={Dakota} height={"180pt"} alt="logo" />
        </IgmSponsor>
        <SecondaryText>E muitas outras...</SecondaryText>
      </SponsorContent>
    </Content>
  );
}

export default AboutSectionThree;
