import styled from "styled-components";
import Azosans from "../../../assets/TEXTO-AzoSans-Regular.ttf";


export const Content = styled.div`
  padding-bottom: 5%;
  background-color: #2f3192;
  @media (min-width: 500px) {
    display: flex;
  }
  color: black;
`;

export const FinalContent = styled.div`
  background-color: #46afef;
  @media (min-width: 500px) {
    display: flex;
  }
  color: black;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 500px) {
    h1 {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      font-size: 3vmin;
      margin-bottom: 2vmin;
    }
    span {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      padding-bottom: 2vmin;
      font-size: 2vmin;
    }
    img {
      height: auto;
      width: 60%;
      padding-top: 20px;
    }
    width: 50%;
    margin-left: 15%;
    
  }
  @media (max-width: 500px) {
    h1 {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      font-size: 6vmin;
      margin-bottom: 2vmin;
    }
    span {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      padding-bottom: 2vmin;
      font-size: 4vmin;
    }
    img {
      height: auto;
      width: 50%;
      padding-top: 20px;
    }
    align-items: center;
    width: 100%;
    text-align: center;
  }
`;

export const TitleText = styled.text`
  color: #666666;
  font-size: 30pt;
  @media (min-width: 500px) {
    font-size: 30pt;
    padding: 5px 25px 5px 25px;
  }
  @media (max-width: 500px) {
    font-size: 35pt;
    text-align: center;
  }
  font-weight: bold;
  font-family: Azosans;
  src: url(${Azosans}) format("truetype");
`;

export const RigthContent = styled.div`
  @media (max-width: 500px) {
    text-align:center;
    width: 100%;
  }
  @media (min-width: 500px) {
    display: flex;
    width: 50%;
  }
  /* background-color: #000000; */
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 500px) {
    h1 {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      font-size: 3vmin;
      margin-bottom: 2vmin;
    }
    span {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      padding-bottom: 2vmin;
      font-size: 2vmin;
    }
    width: 30%;
    
  }
  @media (max-width: 500px) {
    width: 100%;
    align-items:center;
    h1 {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      font-size: 6vmin;
      margin-bottom: 2vmin;
    }
    span {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      padding-bottom: 2vmin;
      font-size: 4vmin;
    }
  }
`;

export const RightPart = styled.div`
  @media (min-width: 500px) {
    h1 {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      font-size: 3vmin;
      margin-bottom: 2vmin;
    }
    span {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      padding-bottom: 2vmin;
      font-size: 2vmin;
    }
    width: 100%;
    
  }
  @media (max-width: 500px) {
    width: 80%;
    h1 {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      font-size: 6vmin;
      margin-bottom: 2vmin;
    }
    span {
      font-family: "azo-sans-web";
      src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff2"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("woff"),
        url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
          format("opentype");
      color: white;
      padding-bottom: 2vmin;
      font-size: 4vmin;
    }
  }
`;
