import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 5% 0% 5% 0%;
  background-color: #eeeeee;
  @media (min-width: 500px) {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
  }
`;

export const InputInfo = styled.input`
  padding: 20pt 0pt 20pt 10pt;
  width: 60%;
  border-radius: 10px;
  border-width: 0px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 20pt;
  font-size: 15pt;
  ::placeholder {
    position: relative;
    color: #666666;
    font-size: 15pt;
  }
  @media (max-width: 550px) {
    width: 80%;
    padding: 7pt 0pt 15pt 10pt;
    font-size: 13pt;
    border-radius: 10px;
    border-width: 0px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20pt;

    ::placeholder {
      top: 0pt;
      left: 0pt;
      color: #666666;
      font-size: 13pt;
    }
  }
`;
export const InputMessage = styled.input`
  padding: 20pt 0pt 110pt 10pt;
  font-size: 15pt;
  width: 60%;
  border-radius: 10px;
  border-width: 0px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 50pt;
  ::placeholder{
    position: relative;
    color: #666666;
    font-size: 15pt;
    
  }
  @media(max-width: 500px){
    width: 80%;
    padding: 20pt 0pt 90pt 10pt
    font-size: 13pt;

    ::placeholder{
      top: 0pt;
      left: 0pt;
      color: #666666;
      font-size: 13pt;
      
    }
  }
`;

export const TitleText = styled.text`
  color: #666666;
  font-size: 30pt;
  @media (min-width: 500px) {
    font-size: 30pt;
    padding: 5px 40px 5px 40px;
  }
  @media (max-width: 500px) {
    font-size: 24pt;
    text-align: center;
  }
  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const BlueTitleText = styled.text`
  color: #46afef;
  font-size: 30pt;
  @media (max-width: 500px) {
    font-size: 24pt;
    text-align: center;
  }

  font-weight: bold;
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;
// width: 40%;
// float: right;
// display: block;
export const SmallText = styled.text`
  color: #666666;
  font-size: 20pt;
  width: 60%;
  margin-top: 30pt;
  text-align: center;
  @media (min-width: 500px) {
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 2.5vmin;
    text-align: center;
    width: 60%;
  }
  @media (max-width: 500px) {
    font-size: 11pt;
    text-align: center;
    width: 100%;
  }
  font-family: "azo-sans-web";
  src: url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bc6b0e/00000000000000007735a2dd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`;

export const FormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 30pt 0pt 10pt 0pt;
`;
