import React from "react";
import "./styles/aboutsectiontwo.css";
import {
  BlueTitleText,
  Content,
  LeftContent,
  MainContent,
  OtherCenterContent,
  OtherContent,
  OtherLeftContent,
  OtherRightContent,
  RigthContent,
  SmallText,
  SmallTitleText,
  SubContent,
  TitleText,
} from "./styles/aboutsectiontwo.styles";

function AboutSectionTwo() {
  return (
    <Content id="aboutsectiontwo">
      <MainContent>
        <SubContent>
          <LeftContent>
            <TitleText>
              <span>
                Um pouco sobre
                <br />
                nossos{" "}
              </span>{" "}
              <BlueTitleText>números</BlueTitleText>.
            </TitleText>
          </LeftContent>
          <RigthContent>
            <SmallText>
              Todas as cargas são asseguradas, rastreadas e monitoradas via
              satélite com equipamentos de última geração. De grande
              capilaridade, com nossos mais de 200 colaboradores, atendemos em
              cerca de 5.000 municípios do Brasil em modernos centros de
              distribuições com mais de 7000 m² de área total.
            </SmallText>
          </RigthContent>
        </SubContent>
        <center>
          <OtherContent>
            <OtherLeftContent>
              <BlueTitleText>110</BlueTitleText>
              <SmallTitleText>Veículos</SmallTitleText>
              <SmallText>
                Carretas e veículos
                <br />
                de coleta/entrega.
              </SmallText>
            </OtherLeftContent>
            <OtherCenterContent>
              <BlueTitleText>000</BlueTitleText>
              <SmallTitleText>Viagens/mês</SmallTitleText>
              <SmallText>Lorem ipsum lorem ipsum.</SmallText>
            </OtherCenterContent>
            <OtherRightContent>
              <BlueTitleText>
                <SmallTitleText>+</SmallTitleText>500
              </BlueTitleText>
              <SmallTitleText>Cidades</SmallTitleText>
              <SmallText>Em 4 estados de atuação.</SmallText>
            </OtherRightContent>
          </OtherContent>
        </center>
      </MainContent>
    </Content>
  );
}

export default AboutSectionTwo;
