import React from "react";
import { GrMail } from "react-icons/gr";
import { ImLocation, ImPhone } from "react-icons/im";
import logo from "../../assets/te_logo.png";
import "./styles/footer.css";
import {
  Content,
  FinalContent,
  LeftContent,
  LeftPart,
  RightPart,
  RigthContent,
} from "./styles/footer.styles";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";

function FooterSection() {
  return (
    <>
      <Content>
        <LeftContent>
          <img src={logo} alt="logo" />
          <h1>
            A segurança
            <br />
            em transporte.
          </h1>
          <span>
            A Transportadora Econômica é a empresa que
            <br />
            pode te ajudar a encontrar o que precisa.
          </span>
        </LeftContent>
        <RigthContent>
          <LeftPart>
            <Router>
              <h1>Nosso site</h1>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionzero"
                >
                  Início
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionone"
                >
                  Quem somos
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectiontwo"
                >
                  Serviços
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionthree"
                >
                  Onde atuamos
                </HashLink>
              </span>
              <span>
                <HashLink
                  style={{ color: "white", textDecoration: "none" }}
                  smooth
                  to="#aboutsectionfour"
                >
                  Contato
                </HashLink>
              </span>
            </Router>
          </LeftPart>
          <center>
            <RightPart>
              <h1>Entre em contato</h1>
              <ImLocation style={{ color: "white", marginRight: "10px" }} />
              <span>
                Rua Izabel Bezerra, 345 - Fortaleza
                <br />
                Rua Santa Amelia, 134 - Guarulhos
              </span>
              <br />
              <br />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <GrMail style={{ color: "white", marginRight: "10px" }} />
              <span>comercial@transeconomica.com.br</span>
              </div>
              <br />
              <ImPhone style={{ color: "white", marginRight: "10px" }} />
              <span>(85) 3229-9666</span>
              <br />
            </RightPart>
          </center>
        </RigthContent>
      </Content>
      <FinalContent>
        <LeftContent>
          <span>© 2022 Transportadora Econômica. All Rights Reserved.</span>
        </LeftContent>
        <RigthContent></RigthContent>
      </FinalContent>
    </>
  );
}

export default FooterSection;
