import React from "react";
import AboutSectionOne from "./aboutsectionone";
import AboutSectionThree from "./aboutsectionthree";
import AboutSectionTwo from "./aboutsectiontwo";
import ContactSection from "./contactsection";

function About() {
  return (
    <div className="App">
      <AboutSectionOne></AboutSectionOne>
      <AboutSectionTwo></AboutSectionTwo>
      <AboutSectionThree></AboutSectionThree>
      <ContactSection></ContactSection>
    </div>
  );
}

export default About;
