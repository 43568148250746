import { React, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import logo from "../../../assets/Assinatura_Horizontal.png";
import mask from "./cpnjMask";
import "./login-modal.css";
import axios from "axios";
import ClipLoader from "react-spinners/ScaleLoader";

const LoginModal = ({ setLoginModalIsOpen, onOpenListModal }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true)
    var data = JSON.stringify({
      cnpj: login.replaceAll(".", "").replaceAll("-", "").replaceAll("/", ""),
      credencial: password,
    });

    var config = {
      method: "post",
      url: "https://transportadora-backend.deway.com.br/autenticar",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 401) {
          setLoading(false)
          setError(response.data.msg);
        } else {
          window.localStorage.setItem("token-service", response.data.token);
          setLoading(false)
          onOpenListModal(false);
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
      });
  };

  function handleChangeMask(event) {
    const { value } = event.target;
    setLogin(mask(value));
  }

  return (
    <>
      <div className="content-login">
        <div style={{ backgroundColor: "white", height: "15%" }}>
          <center>
            <img
              className="img-main"
              style={{ width: "60%", height: "auto" }}
              src={logo}
              alt="logo"
            />
          </center>
        </div>
        <form className="form-main" style={{ flex: 1 }}>
          <center>
            <label className="txt-main-login">
              Informe seu código de cliente e <br />
              sua senha de segurança.
            </label>
          </center>

          <input
            className="input-main"
            maxLength={18}
            type="text"
            value={login}
            placeholder="CNPJ"
            onChange={handleChangeMask}
            id="username"
          ></input>
          <input
            className="input-main"
            type="password"
            placeholder="Senha"
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          ></input>
          <center>
          <BrowserView>
          {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "50px",
                  marginTop: "20px",
                }}
              >
                <ClipLoader height="14" width="5" />
              </div>
            ) : null}
          </BrowserView>
          <MobileView>
          {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <ClipLoader height="14" width="5" />
              </div>
            ) : null}
          </MobileView>
            <label className="txt-error">{error}</label>
          </center>
          {/* <label className="txt-forget">Esqueceu a senha?</label> */}
          <BrowserView>
            <div onClick={onSubmit} className="btn-open-modal">
              <text>Entrar</text>
            </div>
          </BrowserView>
          <MobileView>
            <div onClick={onSubmit} className="btn-open-modal">
              <text>Log In</text>
            </div>
          </MobileView>
          <center>
            <label className="txt-register">{error}</label>
          </center>
        </form>
      </div>
      <div
        className="loginModalWhiteBG"
        onClick={() => {
          setLoginModalIsOpen(false);
          document.body.style.overflowY = "scroll";
        }}
      />
    </>
  );
};

export default LoginModal;
