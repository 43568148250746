import React from "react";
import styled from "styled-components";
import colors from "../colors";

export const Content = styled.div``;

export const Link = styled.a`
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 2.1vmin;
  background-color: ${colors.BLUE500};
  height: 4.5vmin;
  width: ${(props) => props.width};
  display: flex;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 30vmin;
    height: 15vmin;
    font-size: 3vmin;
  }
`;

function Button(props) {
  return (
    <Link width={props.width} marginRight={props.marginRight} marginLeft={props.marginLeft}>
      <Content>{props.text}</Content>
    </Link>
  );
}

export default Button;
