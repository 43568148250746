import styled from "styled-components";

export const Content = styled.div`
  background-image: url(${require(`../../assets/te_truck.png`)});
  background-repeat: no-repeat;
  background-position: center center;
  height: 55vmin;
  background-size: cover;
  display: flex;
  color: white;
  padding: 20px 60px 0px 100px;
  @media (max-width: 1200px) {
    padding: 20px 20px 0px 50px;
    height: 400px;
  }
  @media (max-width: 990px) {
    padding: 20px 0px 0px 20px;
    height: 450px;
  }
  @media (max-width: 500px) and (min-width: 1px) {
    height: 110vmin;
  }
  @media (max-width: 890px) and (min-width: 500px) {
    height: 60vmin;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  

  @media (max-width: 1600px) and (min-width: 900px) {
    h1 {
      padding-top: 30px;
      margin-bottom: 10px; 
      margin-left: 5vh;
    }
    span {
      padding-bottom: 15px;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    h1 {
      margin-left: 4vh;
      padding-top: 35px;
    }
    span {
      padding-bottom: 15px;
    }
    align-items:center;
    text-align: center;
  }
`;

export const RigthContent = styled.div`
  margin-left:20%;
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: center;
  /* background-color: #000000; */
  span {
    padding-right: 3.1vmin;
    font-size: 1.8vmin;
  }
  div {
    display: flex;
    flex-direction: row;
    height: 90px;
    align-items: center;
  }
`;
