import React from "react";
import Button from "../button";
import {
  BlueTitleText,
  Content,
  FormContent,
  InputInfo,
  InputMessage,
  SmallText,
  TextContent,
  TitleText,
} from "./styles/contactsection.styles";
import "./mask-style.css";
import InputMask from "react-input-mask";
function ContactSection() {
  return (
    <Content id="aboutsectionfour" translate="no">
          <TextContent>
            <TitleText>
              <span>
              Ficou interessado em nossos </span>  
              <BlueTitleText><span>serviços</span></BlueTitleText> <span>?</span>
            </TitleText>
            <SmallText>
              Entre em contato com a Transportadora Econômica preenchendo
              corretamente todos os campos do formulário abaixo. Se preferir,
              envie um e-mail para sac@transeconomica.com.br.
            </SmallText>
          </TextContent>
          <FormContent>
              <InputInfo placeholder="Digite seu nome"  />
              <InputInfo placeholder="Digite seu e-mail" />
              <InputMask mask='(99) 99999-9999' className="phoneMask" placeholder="Digite seu telefone" />
              <InputMessage placeholder="Digite sua mensagem" />
              <Button width={"30vmin"} marginRight={"1vh"} text={"Enviar Mensagem"} />
          </FormContent>
    </Content>
  );
}

export default ContactSection;
