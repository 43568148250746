import { React, useRef, useState, useEffect } from "react";
import { RiCloseLine, RiDownload2Line, RiArrowLeftLine } from "react-icons/ri";
import ClipLoader from "react-spinners/ScaleLoader";
import logo from "../../../assets/te_logo.png";
import "./Modal-mobile.css";
const ModalMobile = ({
  onCloseModalMobile,
  onOpenModalMobileLoadDetail,
  onReturnLoginMobileModal,
}) => {
  const [loading, setLoading] = useState(false);

  function getCtes(query) {
    setCtes([]);
    setLoading(true);
    var axios = require("axios");
    var data = JSON.stringify({
      token: localStorage.getItem("token-service"),
      initialDate: ref.current.value ? ref.current.value : "",
      finalDate: ref2.current.value ? ref2.current.value : "",
      query: query ? { query } : undefined,
    });

    var config = {
      method: "post",
      url: "https://transportadora-backend.deway.com.br/ctes",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        console.log(response.data);
        setCtes(response.data.ctes);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  }

  const [ctes, setCtes] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getCtes(undefined);
  }, []);

  const [filterStr, setFilterStr] = useState("");

  // const filteredElements = elements.filter(
  //   (e) => e.ca.includes(filterStr) || e.note.includes(filterStr)
  // );

  // console.log(filteredElements);

  const ref = useRef();
  const ref2 = useRef();
  return (
    <>
      <div
        className="darkBGMobile"
        onClick={() => {
          onCloseModalMobile(false);
          document.body.style.overflowY = "scroll";
        }}
      />
      <div className="centeredMobile">
        <div className="modalMobile">
          <div className="modalHeaderMobile">
            <button
              className="returnBtnMobile"
              onClick={() => {
                onReturnLoginMobileModal(false);
                document.body.style.overflowY = "hidden";
              }}
            >
              <RiArrowLeftLine style={{ marginBottom: "-3px" }} />
            </button>
            <div className="modalHeaderTopMobile">
              <img
                style={{ marginTop: "10px" }}
                src={logo}
                height={"auto"}
                width={"60%"}
                alt="logo"
              />
            </div>
            {/* <div className="modalHeaderTextMobile">
              <span>Justo Dantas</span>
              <span>CNPJ 00000000000000</span>
            </div>
            <div className="modalHeaderCenterTextMobile">
              <span>
                Informe o Número do Conhecimento
                <br /> ou da Nota Fiscal da carga que
                <br />
                deseja localizar.
              </span>
            </div> */}
          </div>
          <center>
            <div className="modalHeaderBottomMobile">
              <input
                type="text"
                value={search}
                onChange={(e) => {
                  // setFilterStr(e.target.value) || setFilterStr(e.target.value);
                  setSearch(e.target.value);
                }}
                className="searchInputMobile"
                placeholder="Busque pelo código de CTe ou nota fiscal"
              />
              <button
                className="searchButtonMobile"
                type="button"
                onClick={() => {
                  getCtes(search);
                }}
              >
                Localizar
              </button>
            </div>
          </center>
          <div className="modalHeaderFinalMobile">
            <input
              className="dateInputMobile"
              placeholder="Periodo inicial"
              type="text"
              ref={ref}
              onFocus={() => (ref.current.type = "date")}
              onBlur={() => (ref.current.type = "date")}
            />
            <input
              className="dateInputMobile"
              placeholder="Periodo final"
              type="text"
              ref={ref2}
              onFocus={() => (ref2.current.type = "date")}
              onBlur={() => (ref2.current.type = "date")}
            />
          </div>
          <button
            className="closeBtnMobile"
            onClick={() => {
              onCloseModalMobile(false);
              document.body.style.overflowY = "scroll";
            }}
          >
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <ClipLoader color="#FFF" height="14" width="5" />
            </div>
          ) : null}
          <div className="modalContentMobile">
            {ctes.length > 0 ? (
              ctes.map((service, i) => {
                return (
                  <>
                    <div>
                      <div className="contentMobile">
                        <div>
                          <text className="contentTitleMobile">
                            Número do CTe
                          </text>
                          <br />
                          <span className="contentSpanMobile">
                            {service.cte}
                          </span>
                          <br />
                          <text className="contentTitleMobile">
                            Nota fiscal
                          </text>
                          <br />
                          <span>{service.nota_fiscal.numero}</span>
                          <br />
                          <text className="contentTitleMobile">Remetente</text>
                          <br />
                          <span>{service.remetente}</span>
                        </div>
                        <div className="contentSeparatorMobile">
                          <text className="contentTitleMobile">Destino</text>
                          <br />
                          <span>{service.destinatario}</span>
                          <br />
                          <text className="contentTitleMobile">Trecho</text>
                          <br />
                          <span>{service.trecho}</span>
                          <br />
                          <text className="contentTitleMobile">Status</text>
                          <br />
                          <span>{service.status.descricao}</span>
                        </div>
                        <text translate="no" onClick={() => onOpenModalMobileLoadDetail(false, service)}>
                        Visualizar
                      </text>
                      </div>
                      {/* <div className="downloadContentMobile">
                      <RiDownload2Line style={{ fontSize: "12vw" }} />
                    </div> */}
                    </div>
                  </>
                );
              })
            ) : (
              <center>
                {!loading ? (
                  <div
                    style={{
                      color: "#FFF",
                      fontSize: "20px ",
                    }}
                  >
                    Nenhum resultado encontrado
                  </div>
                ) : null}
              </center>
            )}
          </div>
          <div className="modalActionsMobile"></div>
        </div>
      </div>
    </>
  );
};

export default ModalMobile;
